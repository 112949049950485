$(function(){
  $('#scroll_button').click(function(){
    let speed = 1000;
    let element = document.getElementById('click_scroll'); // 移動させたい位置の要素を取得
    let rect = element.getBoundingClientRect();
    let position = rect.top-55;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
});

$(function() {
    var topBtn = $('#top_scroll');
    var topButton = $('#personal_contact');
    var topButtonClass = $('.personal_contact');
    //スクロールが100に達したらボタン表示
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        topButton.text(topButton.text().replace("約1分!服シェア相性診断はこちら", "+"));
        topButtonClass.addClass("pesonal_contact_right");
      } else {
        topButton.text(topButton.text().replace("+", "約1分!服シェア相性診断はこちら"));
        topButtonClass.removeClass("pesonal_contact_right");
      }
    });
    $('#top_scroll').click(function(){
      let speed = 1000;
      let element = document.getElementById('click_top'); // 移動させたい位置の要素を取得
      let rect = element.getBoundingClientRect();
      let position = rect.top-55;
      $("html, body").animate({scrollTop:position}, speed, "swing");
      return false;
    });
});
